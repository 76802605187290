import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box, Stack } from '@mui/system';
import { sv as locale } from '@norban/locale';
import React, { useState } from 'react';

import EmailDialog from '../../components/EmailDialog';
import SMSDialog from '../../components/SMSDialog';
import {
  BofBakedLeadTemplatesDocument,
  BofSendSmsDocument,
  BulkEmailType,
  BulkEmailUnsubscribeType,
} from '../../generated/backend/graphql';
import usePopupAlert from '../../hooks/usePopupAlert';
import useSession from '../../hooks/useSession';

import LeadTemplatesSenderPicker from './components/LeadTemplatesSenderPicker';
import LeadTemplatesTemplatePicker, {
  BakedLeadTemplate,
} from './components/LeadTemplatesTemplatePicker';

type Props = {
  userId: string;
  homeId: string | undefined;
  email: string | undefined;
  phone: string | undefined;
};

const useStyles = makeStyles(() => ({
  message: {
    whiteSpace: 'pre-wrap',
  },
}));

const determineContactMethod = (
  email: string | undefined,
  phone: string | undefined,
) => {
  if (email) {
    return 'email';
  }
  if (phone) {
    return 'sms';
  }

  return 'none';
};

const BakedLeadTemplates = ({ userId, homeId, email, phone }: Props) => {
  const L = locale.backoffice;

  const classes = useStyles();

  const { userId: adminId } = useSession();
  const [selectedSenderId, setSelectedSenderId] = useState(
    adminId?.toString() ?? '',
  );
  const [selectedTemplate, setSelectedTemplate] =
    useState<null | BakedLeadTemplate>(null);
  const [modifiedSubject, setModifiedSubject] = useState<string>('');
  const [modifiedMessage, setModifiedMessage] = useState<string>('');
  const [contactMethod, setContactMethod] = useState(
    determineContactMethod(email, phone),
  );

  const { PopupAlert, showPopupAlert } = usePopupAlert();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [SMSDialogOpen, setSMSDialogOpen] = useState(false);

  const numHomeId = homeId ? parseInt(homeId, 10) : undefined;

  const { data } = useQuery(BofBakedLeadTemplatesDocument, {
    variables: {
      userId: userId?.toString() ?? '',
      senderId: selectedSenderId,
      customTemplate: selectedTemplate?.message,
      customSubject: selectedTemplate?.subject,
    },
    skip: !selectedTemplate,
  });
  const { subject, message } = data?.bakedLeadTemplate ?? {};
  const { name } = selectedTemplate ?? {};
  // Convert name to a recognizable tag for email detection
  const tagSuffix = name?.toLowerCase().replace(/\s+/g, '-') ?? 'custom';
  const tag = `lead-template-${tagSuffix}`;

  const currentSubject =
    modifiedSubject !== '' ? modifiedSubject : (subject ?? '');
  const currentMessage =
    modifiedMessage !== '' ? modifiedMessage : (message ?? '');

  // FIXME - move to useSMS...
  const [sendSMS] = useMutation(BofSendSmsDocument);

  const handleSendSMS = async (message: string) => {
    if (!selectedSenderId) {
      showPopupAlert(L.leadTemplates.noAssignedAgent, 'error');
      return;
    }

    if (!userId) {
      showPopupAlert(L.leadTemplates.noUser, 'error');
      return;
    }

    if (!message) {
      showPopupAlert(L.leadTemplates.noMessage, 'error');
      return;
    }

    try {
      await sendSMS({
        variables: {
          input: {
            message,
            senderId: selectedSenderId,
            recipientId: userId,
            template: 'custom1',
          },
        },
      });
      showPopupAlert(L.leadTemplates.smsSent, 'success');
    } catch (error) {
      showPopupAlert(L.leadTemplates.failedSendingSMS, 'error');
    }
  };

  return (
    <Paper>
      <Box p={2}>
        <Stack spacing={2} direction="column" alignItems="flex-start">
          <LeadTemplatesTemplatePicker
            selectedTemplate={selectedTemplate}
            onSelectTemplate={template => {
              setModifiedMessage('');
              setModifiedSubject('');
              setSelectedTemplate(template);
            }}
          />
          <LeadTemplatesSenderPicker
            selectedAgentId={selectedSenderId ?? null}
            onSelectAgentId={selectedSenderId =>
              setSelectedSenderId(selectedSenderId ?? '')
            }
          />
          <Grid container>
            <Grid item xs={12} sm={12} md={9} lg={6}>
              <pre className={classes.message}>
                {currentSubject}
                <br />
                <br />
                {currentMessage}
              </pre>
            </Grid>
          </Grid>
          <RadioGroup
            row
            aria-label="Kontaktsätt"
            name="contactMethod"
            value={contactMethod}
            onChange={event => setContactMethod(event.target.value)}
          >
            <FormControlLabel
              value="email"
              control={<Radio />}
              label="Mejl"
              defaultChecked={!!email}
              disabled={!data?.bakedLeadTemplate || !email}
            />
            <FormControlLabel
              value="sms"
              control={<Radio />}
              label="SMS"
              defaultChecked={!email}
              disabled={!data?.bakedLeadTemplate || !phone}
            />
          </RadioGroup>
          <Button
            variant="contained"
            disabled={!data?.bakedLeadTemplate || (!email && !phone)}
            size="small"
            color="secondary"
            onClick={() => {
              if (contactMethod === 'email') {
                setDialogOpen(true);
              } else if (contactMethod === 'sms') {
                setSMSDialogOpen(true);
              }
            }}
          >
            Skicka
          </Button>
        </Stack>
      </Box>
      <EmailDialog
        defaultType={BulkEmailType.LeadTemplateCustom1}
        options={[
          {
            type: BulkEmailType.LeadTemplateCustom1,
            unsubscribeType: BulkEmailUnsubscribeType.None,
            description: 'Bearbetningsmall custom1',
            subject: currentSubject,
            message: currentMessage,
            signatureType: 'withLogo',
            tag,
          },
        ]}
        homeId={numHomeId}
        open={dialogOpen}
        recipients={[{ id: userId, email: email ?? '' }]}
        senderId={selectedSenderId}
        skipFirstTwoSteps
        userFilter={{}}
        userFilterCount={-1}
        onClose={() => setDialogOpen(false)}
        onSent={() => {}}
      />
      <SMSDialog
        message={currentMessage}
        phone={phone ?? ''}
        open={SMSDialogOpen}
        onClose={() => setSMSDialogOpen(false)}
        onSend={handleSendSMS}
      />
      <PopupAlert />
    </Paper>
  );
};

export default BakedLeadTemplates;
