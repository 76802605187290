import { useMutation, useQuery } from '@apollo/client';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { GridDeleteIcon } from '@mui/x-data-grid';
import { sv as locale } from '@norban/locale';
import { addDays } from 'date-fns';
import React from 'react';

import {
  BofFollowerCandidateCardMarkForKickstartDocument,
  BofFollowerCandidateCardMarkedForKickstartDocument,
} from '../../../../generated/backend/graphql';

type Props = {
  homeId: string;
  onError: () => void;
};

const FollowerCandidateCard = ({ homeId, onError }: Props) => {
  const L = locale.backoffice.followerCandidate;

  const tomorrowDate = addDays(new Date(), 1);

  const { data, loading } = useQuery(
    BofFollowerCandidateCardMarkedForKickstartDocument,
    {
      variables: { homeId },
    },
  );
  const { markedForKickstart } = data?.home || {};
  const [markForKickstart] = useMutation(
    BofFollowerCandidateCardMarkForKickstartDocument,
  );

  const markedForKickstartDate = markedForKickstart
    ? new Date(markedForKickstart)
    : null;
  const markedForTomorrow = markedForKickstartDate
    ? tomorrowDate.toDateString() === markedForKickstartDate.toDateString()
    : false;

  return (
    <Card>
      <CardHeader title={L.title} />
      <CardContent>
        <Stack spacing={2} alignItems="flex-start">
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ whiteSpace: 'pre-wrap' }}
          >
            {L.content}
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={async () => {
              await markForKickstart({
                onError,
                variables: {
                  homeId,
                  input: {
                    markedForKickstart: tomorrowDate.toISOString(),
                  },
                },
              });
            }}
            disabled={markedForTomorrow || loading}
          >
            {L.setAsCandidate}
          </Button>
          {markedForKickstartDate && (
            <Box
              alignItems="center"
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              {markedForTomorrow ? (
                <PendingActionsIcon
                  style={{
                    marginRight: '8px',
                  }}
                />
              ) : (
                <CheckCircleIcon
                  style={{
                    color: 'green',
                    marginRight: '8px',
                  }}
                />
              )}
              {new Intl.DateTimeFormat('sv-SE', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              }).format(markedForKickstartDate)}
              <Box ml={1} display="inline">
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={async () =>
                    markForKickstart({
                      onError,
                      variables: {
                        homeId,
                        input: {
                          markedForKickstart: null,
                        },
                      },
                    })
                  }
                >
                  <GridDeleteIcon />
                </IconButton>
              </Box>
            </Box>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default FollowerCandidateCard;
