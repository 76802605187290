import { useMutation, useQuery } from '@apollo/client';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { sv as locale } from '@norban/locale';
import { addDays } from 'date-fns';
import React, { useMemo } from 'react';

import {
  DeleteHomeMonitorCandidateDocument,
  HomeMonitorCandidatesDocument,
  SetHomeAsMonitorCandidateDocument,
} from '../../../../generated/backend/graphql';

type Props = {
  homeId: string;
  onError: () => void;
};

const MonitorCandidateCard = ({ homeId, onError }: Props) => {
  const L = locale.backoffice.monitorCandidate;

  const [setHomeAsMonitoringCandidate] = useMutation(
    SetHomeAsMonitorCandidateDocument,
    {
      onError,
      variables: {
        homeId,
      },
      refetchQueries: [HomeMonitorCandidatesDocument],
    },
  );

  const [deleteMonitoringCandidate] = useMutation(
    DeleteHomeMonitorCandidateDocument,
    {
      onError,
      refetchQueries: [HomeMonitorCandidatesDocument],
    },
  );

  const { data, loading } = useQuery(HomeMonitorCandidatesDocument, {
    variables: {
      homeId,
    },
  });

  const latestCandidate = useMemo(() => {
    if (!data?.homeMonitorCandidates.length) return null;
    return data.homeMonitorCandidates.reduce((latest, candidate) => {
      const candidateDate = new Date(candidate.createdAt);
      return latest && candidateDate > new Date(latest.createdAt)
        ? candidate
        : latest;
    }, data.homeMonitorCandidates[0]);
  }, [data?.homeMonitorCandidates]);
  const latestCandidateIsToday = useMemo(() => {
    if (!latestCandidate) return false;
    return (
      new Date(latestCandidate.createdAt).toDateString() ===
      new Date().toDateString()
    );
  }, [latestCandidate]);

  const renderCandidate = () => {
    return (
      <Box
        key={latestCandidate?.id}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {!!latestCandidate &&
          (latestCandidateIsToday ? (
            <PendingActionsIcon
              style={{
                marginRight: '8px',
              }}
            />
          ) : (
            <CheckCircleIcon
              style={{
                color: 'green',
                marginRight: '8px',
              }}
            />
          ))}
        {new Intl.DateTimeFormat('sv-SE', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        }).format(addDays(new Date(latestCandidate?.createdAt), 1))}
        <Box ml={1}>
          <IconButton
            edge="end"
            color="inherit"
            disabled={loading || !latestCandidateIsToday}
            onClick={() =>
              deleteMonitoringCandidate({
                variables: { id: latestCandidate?.id ?? '' },
              })
            }
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>
    );
  };

  return (
    <Card>
      <CardHeader title={L.title} />
      <CardContent>
        <Stack spacing={2} alignItems="flex-start">
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ whiteSpace: 'pre-wrap' }}
          >
            {L.content}
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            type="button"
            onClick={() => setHomeAsMonitoringCandidate()}
            disabled={latestCandidateIsToday || loading}
          >
            {L.setAsCandidate}
          </Button>
          {!!latestCandidate && renderCandidate()}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default MonitorCandidateCard;
