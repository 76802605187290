import { useQuery } from '@apollo/client';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Table,
  Typography,
} from '@mui/material';
import { sv as locale } from '@norban/locale';
import React, { useMemo, useState } from 'react';

import EmailDialog from '../../../../components/EmailDialog';
import FilterSection from '../../../../components/FilterSection';
import QueryError from '../../../../components/QueryError';
import SpinnerRow from '../../../../components/SpinnerRow';
import {
  BofAreasDocument,
  BofFollowersBriefQuery,
  BofHomeInviteFetchAssociatedAreasDocument,
  BofHomeInviteFetchHomeDocument,
  BulkEmailType,
  BulkEmailUnsubscribeType,
  FollowerUniqueType,
  Role,
} from '../../../../generated/backend/graphql';
import { useFetchFollowers } from '../../../../hooks/useFetchFollowers';
import useFilterRowArea from '../../../../hooks/useFilterRowArea';
import useFilterRowLivingArea from '../../../../hooks/useFilterRowLivingArea';
import useFilterRowNumberOfRooms from '../../../../hooks/useFilterRowNumberOfRooms';
import useStateWithDelayedCallback from '../../../../hooks/useStateWithDelayedCallback';
import { findAreaByIds, flattenAreas } from '../../../../utils/area';

type Props = {
  homeId: string;
};

const HomeInvite = ({ homeId }: Props) => {
  const L = locale.backoffice;

  const defaultFilter = {
    areaIds: [] as string[],
    areasEnabled: false,
    roomsRangeEnabled: false,
    livingAreaRangeEnabled: false,
    roomsRange: [undefined, undefined] as [
      number | undefined,
      number | undefined,
    ],
    livingAreaRange: [undefined, undefined] as [
      number | undefined,
      number | undefined,
    ],
    homeStatesEnabled: false,
    homeStates: [],
    createdDateEnabled: false,
    createdDateRange: [undefined, undefined] as [
      string | undefined,
      string | undefined,
    ],
    homeSection: true,
  };
  const [dialogOpen, setDialogOpen] = useState(false);
  const [networkFilter, setNetworkFilter] = useState(defaultFilter);
  const [filter, setFilterDelayed, setFilterDirect] =
    useStateWithDelayedCallback(
      defaultFilter,
      value =>
        setNetworkFilter({
          ...filter,
          ...value,
        }),
      300,
    );

  const { data: dataAreas, error: errorAreas } = useQuery(BofAreasDocument);

  const { rootAreas } = dataAreas ?? { rootAreas: [] };

  const areaIds = useMemo(
    () =>
      findAreaByIds(filter.areaIds, rootAreas ?? [])
        .flatMap(area => flattenAreas(area))
        .map(({ id }) => id),
    [filter.areaIds, rootAreas],
  );

  const { data: dataHome, error: errorHome } = useQuery(
    BofHomeInviteFetchHomeDocument,
    {
      variables: {
        id: homeId,
      },
    },
  );
  const { data: dataAA, error: errorAA } = useQuery(
    BofHomeInviteFetchAssociatedAreasDocument,
    {
      variables: {
        id: dataHome?.home.areaId ?? '',
      },
      onCompleted: theData => {
        const associatedAreaIds =
          theData.associatedAreas.map(aa => aa.associatedAreaId) || [];
        let areaIds;
        let roomsRange: [number | undefined, number | undefined] = [
          undefined,
          undefined,
        ];

        if (dataHome?.home.areaId) {
          areaIds = [dataHome?.home.areaId, ...associatedAreaIds];
        } else {
          areaIds = associatedAreaIds;
        }

        if (dataHome?.home.numberOfRooms) {
          roomsRange = [
            Math.floor(dataHome?.home.numberOfRooms),
            dataHome?.home.numberOfRooms,
          ];
        }

        const updatedFilter = {
          ...filter,
          areasEnabled: true,
          areaIds,
          roomsRangeEnabled: true,
          roomsRange,
        };
        setFilterDirect(updatedFilter);
        setNetworkFilter(updatedFilter);
      },
      skip: !dataHome?.home.areaId,
    },
  );

  const loadingFilterData = !dataAreas || !dataHome || !dataAA;

  const { followers, totalCount, followersLoading } =
    useFetchFollowers<BofFollowersBriefQuery>(
      networkFilter.areasEnabled ? areaIds || [] : undefined,
      networkFilter.roomsRangeEnabled ? networkFilter.roomsRange[0] : undefined,
      networkFilter.roomsRangeEnabled ? networkFilter.roomsRange[1] : undefined,
      networkFilter.livingAreaRangeEnabled
        ? networkFilter.livingAreaRange[0]
        : undefined,
      networkFilter.livingAreaRangeEnabled
        ? networkFilter.livingAreaRange[1]
        : undefined,
      networkFilter.createdDateEnabled
        ? networkFilter.createdDateRange[0]
        : undefined,
      networkFilter.createdDateEnabled
        ? networkFilter.createdDateRange[1]
        : undefined,
      FollowerUniqueType.Latest,
      true,
      networkFilter.homeStatesEnabled ? networkFilter.homeStates : undefined,
      [Role.User, Role.Admin],
      homeId,
      1,
      0,
      loadingFilterData,
      'brief',
      'noPagination',
    );

  const areaRow = useFilterRowArea(
    filter.areasEnabled,
    filter.areaIds,
    rootAreas,
    value => setFilterDirect({ ...filter, areasEnabled: value }),
    value => setFilterDirect({ ...filter, areaIds: value }),
  );
  const numberOfRoomsRow = useFilterRowNumberOfRooms(
    filter.roomsRangeEnabled,
    filter.roomsRange,
    value => setFilterDirect({ ...filter, roomsRangeEnabled: value }),
    range => setFilterDelayed({ ...filter, roomsRange: range }),
  );
  const livingArea = useFilterRowLivingArea(
    filter.livingAreaRangeEnabled,
    filter.livingAreaRange,
    value => setFilterDirect({ ...filter, livingAreaRangeEnabled: value }),
    range => setFilterDelayed({ ...filter, livingAreaRange: range }),
  );
  // Disabled until we figure out how to filter out already emailed users
  // const createdDateRange = useFilterRowCreatedDateRange(
  //   filter.createdDateEnabled,
  //   filter.createdDateRange,
  //   value => setFilterDirect({ ...filter, createdDateEnabled: value }),
  //   range => setFilterDelayed({ ...filter, createdDateRange: range }),
  // );

  const currentlyLoading = followersLoading || loadingFilterData;

  if (errorAreas || errorHome || errorAA) {
    return (
      <QueryError
        data={dataAreas ?? dataHome ?? dataAA}
        error={errorAreas ?? errorHome ?? errorAA}
      />
    );
  }

  return (
    <>
      <EmailDialog
        defaultType={BulkEmailType.FollowerRecommendAViewing}
        options={[
          {
            type: BulkEmailType.FollowerRecommendAViewing,
            unsubscribeType: BulkEmailUnsubscribeType.Marketing,
            description: 'Rekommendera visning',
            message: locale.backoffice.emailDialog.recommendAViewingMessage,
            signatureType: 'withoutLogo',
          },
          {
            type: BulkEmailType.FollowerRecommendAHome,
            unsubscribeType: BulkEmailUnsubscribeType.Marketing,
            description: 'Tips om bostad',
            message: locale.backoffice.emailDialog.recommendAHomeMessage,
            signatureType: 'withoutLogo',
          },
        ]}
        recipients={
          followers?.map(follower => ({
            id: follower.user?.id ?? '',
            email: follower.user?.email || '',
          })) || []
        }
        userFilter={{
          homeFilter: {
            homeStates: filter.homeStates,
          },
          // FIXME - more filters needed
        }}
        userFilterCount={-1}
        open={dialogOpen}
        onSent={() => {}}
        onClose={() => setDialogOpen(false)}
        homeId={Number(homeId)}
      />
      <Card>
        <CardHeader title={L.homeInvite.title} />
        <CardContent>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ whiteSpace: 'pre-wrap' }}
          >
            {L.homeInvite.content}
          </Typography>
          <Table size="small">
            <FilterSection
              title={L.homeInvite.filterFollowers}
              expanded={filter.homeSection}
              onExpandedChanged={value =>
                setFilterDirect({ ...filter, homeSection: value })
              }
              filters={[areaRow, numberOfRoomsRow, livingArea]}
            />
            {/*
          Disabled until we figure out how to filter out already emailed users
          <FilterSection
            title=" -- datumfiltrering"
            expanded={filter.homeSection}
            onExpandedChanged={value =>
              setFilterDirect({ ...filter, homeSection: value })
            }
            filters={[createdDateRange]}
          /> */}
          </Table>
          {currentlyLoading && <SpinnerRow />}
          {!currentlyLoading && (
            <Stack mt={2} alignItems="flex-start" gap={2}>
              <Typography>
                {L.homeInvite.followersToEmail}: {followers?.length ?? 0}
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                disabled={totalCount === 0}
                onClick={() => setDialogOpen(true)}
              >
                {L.sendEmail}
              </Button>
            </Stack>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default HomeInvite;
