import { useQuery } from '@apollo/client';
import { Autocomplete, Grid, TextField } from '@mui/material';
import React from 'react';

import {
  BofBakedLeadTemplatesListDocument,
  BofBakedLeadTemplatesListQuery,
} from '../../../generated/backend/graphql';

export type BakedLeadTemplate =
  BofBakedLeadTemplatesListQuery['leadTemplates'][0];

type Props = {
  selectedTemplate: BakedLeadTemplate | null;
  onSelectTemplate: (template: BakedLeadTemplate | null) => void;
};

const LeadTemplatesAssignedAgentPicker = ({
  selectedTemplate,
  onSelectTemplate,
}: Props) => {
  const { data } = useQuery(BofBakedLeadTemplatesListDocument);
  const { leadTemplates } = data ?? {};
  const sortedLeadTemplates = leadTemplates
    ?.slice()
    .sort((a, b) => a.name.localeCompare(b.name));
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={9} lg={6}>
        <Autocomplete
          size="small"
          options={
            sortedLeadTemplates?.map(leadTemplate => leadTemplate.name) || []
          }
          value={selectedTemplate?.name ?? null}
          onChange={(_event, value) => {
            const selectedOption = leadTemplates?.find(lt => lt.name === value);
            // eslint-disable-next-line no-console
            onSelectTemplate(selectedOption ?? null);
          }}
          renderInput={params => (
            <TextField {...params} label="Mall" fullWidth />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default LeadTemplatesAssignedAgentPicker;
