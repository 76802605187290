import { useMutation, useQuery } from '@apollo/client';
import React, { useCallback, useMemo, useReducer } from 'react';

import QueryError from '../../../components/QueryError';
import QueryLoading from '../../../components/QueryLoading';
import SlideButton from '../../../components/SlideButton';
import {
  BofUpdateUserDocument,
  BofUpdateUserUnsubscribedChannelsDocument,
  BofUserDocument,
  BofUserUnsubscribedChannelsFragment,
  BofUserUserFragment,
  UpdatedUserInput,
} from '../../../generated/backend/graphql';
import usePopupAlert from '../../../hooks/usePopupAlert';

import UserProfileCard, {
  Action as UserProfileCardAction,
} from './UserProfileCard';

type State =
  | undefined
  | Partial<
      BofUserUserFragment & {
        userUnsubscribedChannels: BofUserUnsubscribedChannelsFragment;
      }
    >;

type Action = UserProfileCardAction | 'reset';

const UserProfileContainer = ({ userId }: { userId: string }) => {
  const { PopupAlert, showPopupAlert } = usePopupAlert();
  const [state, dispatch] = useReducer(
    (state: State, action: Action): State => {
      if (!action) {
        return state;
      }

      if (action === 'reset') {
        return undefined;
      }

      // Special case for email, set to null if empty string
      if (action.key === 'email' && action.value === '') {
        return { ...state, [action.key]: null };
      }

      if ('key' in action) {
        return { ...state, [action.key]: action.value };
      }

      throw new Error('action must contain a "key" and "value" attribute');
    },
    undefined,
  );

  const { loading, error, data } = useQuery(BofUserDocument, {
    variables: { id: userId },
  });

  const userProfile = useMemo(() => {
    if (!data?.user) {
      return undefined;
    }

    return {
      ...data?.user,
      userUnsubscribedChannels: data?.userUnsubscribedChannels,
      ...state,
    };
  }, [data?.user, data?.userUnsubscribedChannels, state]);

  const devices = data?.devices;
  const sessions = data?.sessionInfos;
  const offices = data?.offices;

  const [updateUnsubscribedChannelsMutation] = useMutation(
    BofUpdateUserUnsubscribedChannelsDocument,
    {
      onCompleted: () => dispatch('reset'),
      onError: () => {
        showPopupAlert('Failed to update unsubscribe channels', 'error');
      },
      variables: {
        id: userId.toString(),
        channels:
          userProfile?.userUnsubscribedChannels?.channels.map(c => c.name) ??
          [],
      },
    },
  );

  const userInput: UpdatedUserInput = useMemo(
    () => ({
      name: userProfile?.name,
      email: userProfile?.email,
      phone: userProfile?.phone,
      photoURL: userProfile?.photoURL,
      pin: userProfile?.pin,
      role: userProfile?.role,
      displayName: userProfile?.displayName,
      teamsHook: userProfile?.teamsHook,
      officeId: userProfile?.officeId,
      emailSignatureConfig: userProfile?.emailSignatureConfig,
    }),
    [userProfile],
  );

  const [saveUserMutation] = useMutation(BofUpdateUserDocument, {
    onError: () => {
      showPopupAlert('Failed to update user', 'error');
    },
    variables: {
      id: userId.toString(),
      input: userInput,
    },
  });

  const saveUser = useCallback<React.FormEventHandler<HTMLFormElement>>(
    async evt => {
      evt.preventDefault();
      await saveUserMutation();
      await updateUnsubscribedChannelsMutation();
    },
    [saveUserMutation, updateUnsubscribedChannelsMutation],
  );

  if (loading) {
    return <QueryLoading />;
  }

  if (error) {
    return <QueryError error={error} data={data} />;
  }

  return (
    <div>
      <form onSubmit={saveUser}>
        <UserProfileCard
          userProfile={userProfile}
          devices={devices}
          sessions={sessions}
          offices={offices}
          dispatch={dispatch}
        />

        <SlideButton modified={state !== undefined} />
      </form>

      <PopupAlert />
    </div>
  );
};

export default UserProfileContainer;
